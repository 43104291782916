import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { redirectUser } from "../../api/lynkClient";
import { isEmpty } from "lodash";

import Loading from "../../components/Loading";
import "./redirect.scss";

export default function Redirect() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);

  const redirectTo = query.get("redirect_to");
  const service = query.get("service");
  const projectId = query.get("projectId");
  const paymentId = query.get("paymentId");

  const redirect = useCallback(
    (endpoint, accessToken) => {
      const form = document.createElement("form");
      form.setAttribute("action", endpoint);
      form.setAttribute("method", "post");
      form.setAttribute("style", "opacity: 0");

      const accessTokenInput = document.createElement("input");
      accessTokenInput.name = "accessToken";
      accessTokenInput.value = accessToken;
      form.appendChild(accessTokenInput);
      if (!projectId || !isEmpty(projectId)) {
        const projectIdInput = document.createElement("input");
        projectIdInput.name = "projectId";
        projectIdInput.value = projectId;
        form.appendChild(projectIdInput);
      }

      if (!paymentId || !isEmpty(paymentId)) {
        const paymentIdInput = document.createElement("input");
        paymentIdInput.name = "paymentId";
        paymentIdInput.value = paymentId;
        form.appendChild(paymentIdInput);
      }

      const relayState = document.createElement("input");
      relayState.name = "redirectTo";
      relayState.value = redirectTo;
      form.appendChild(relayState);

      document.body.appendChild(form);
      form.submit();
    },
    [redirectTo, projectId, paymentId]
  );

  useEffect(() => {
    redirectUser({ service })
      .then((response) => {
        const { redirectUrl, accessToken } = response.data;
        setLoading(true);
        setTimeout(() => {
          redirect(redirectUrl, accessToken);
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status !== 401) {
          console.error(error);
        }
      });
  }, [redirect, service]);

  // if (loading) return <Loading />;
  return (
    <div data-testid="redirect-component" className="redirect-component">
      {loading && <Loading />}
    </div>
  );
}
